import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Components/Auth/Login/Login";
import Signup from "./Components/Auth/Signup/Signup";
import Address from "./Components/Auth/Address/Address";
import VerifyOtp from "./Components/Auth/VerifyOtp/VerifyOtp";
import HomePage from "./Homepage";
import Works from "./Components/Works/Works";
import ForgotPassword from "./Components/Auth/ForgotPassword/ForgotPassword";
import { Toaster } from "react-hot-toast";
import ChangePassword from "./Components/Auth/ChangePassword/ChangePassword";
import Platform from "./Components/Platform/Platform";
import Profile from "./Components/Profile";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AboutUs from "./Components/AboutUs/AboutUs";
import { Provider } from "react-redux";
import store from "./store/store";
import ProtectedRoute from "./route/ProtectedRoute";
import Privacy from "./Components/PrivacyPolicy/Privacy";
import Refund from "./Components/RefundPolicy/Refund";
import FloatingIcon from "./Components/FloatingIcon/FloatingIcon";
import TermsAndConditions from "./Components/TermsAndCondition/TermsAndConditionPolicy";
import ProhibitedRestrictedItems from "./Components/ProhibitedItems/ProhibitedItems";

// Initialize Query Client for React Query
const queryClient = new QueryClient();

// Create a browser router with all the routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: (
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup",
        element: (
          <ProtectedRoute>
            <Signup />
          </ProtectedRoute>
        ),
      },
      {
        path: "/address",
        element: <Address />,
      },
      {
        path: "/verify-otp",
        element: (
          <ProtectedRoute>
            <VerifyOtp />
          </ProtectedRoute>
        ),
      },
      {
        path: "/how-it-works",
        element: <Works />,
      },
      {
        path: "/forgot-password",
        element: (
          <ProtectedRoute>
            <ForgotPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "/change-password/:id",
        element: <ChangePassword />,
      },
      {
        path: "/platforms",
        element: <Platform />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/refund-policy",
        element: <Refund />,
      },
      {
        path: "/terms-condition",
        element: <TermsAndConditions/>,
      },
      {
        path: "/prohibited-items",
        element: <ProhibitedRestrictedItems/>,
      },

      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
    ],
  },
]);

// Create root element and render the app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster
      toastOptions={{
        success: {
          style: {
            background: "#fff",
            color: "#15803d",
          },
        },
        error: {
          style: {
            background: "#fff",
            color: "#dc2626",
          },
        },
      }}
    />
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
        {/* <FloatingIcon />  */}
      </Provider>

    </QueryClientProvider>
 
  </React.StrictMode>
);

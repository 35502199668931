import React, { useState } from "react";
import styles from "./Header.module.css";
import FrameImg from "../../assets/Frame.png";
import CartImg from "../../assets/Buy.png";
import ProfileImg from "../../assets/Ellipse.png";
import SearchSvg from "../../assets/Search.svg";
import { Menu, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BulkOrder from "../BulkOrder";
import ShopBharatLogo from "../../assets/shopBharat.svg"

const Navbar = ({ token }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("BHARAT_TOKEN");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("bharatId");
    localStorage.removeItem("userName");
    localStorage.removeItem("email");
    navigate("/");
  };

  const { pathname } = useLocation();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/" className={styles.brand}>
          <img src={ShopBharatLogo} alt="" />
        </Link>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <Menu />
        </div>
        <ul className={`${styles.navlinks} ${isMenuOpen ? styles.open : ""}`}>
          {isMenuOpen && (
            <div className={styles.navbar_close} onClick={toggleMenu}>
              <X />
            </div>
          )}
          <li
            className={
              pathname === "/how-it-works"
                ? styles.on_works_page
                : styles.how_it_works
            }
          >
            <Link to="/how-it-works" className={styles.label}>
              How it Works ?
            </Link>
          </li>
          <li
            className={
              pathname === "/platforms"
                ? styles.on_platforms_page
                : styles.platforms
            }
          >
            <Link to="/platforms" className={styles.label}>
              Shopping Sites
            </Link>
          </li>
          <li onClick={toggleModal} className={styles.bulk_order}>
            <span>
              Bulk Order
            </span>{" "}
          </li>
          <li
            className={
              pathname === "/about-us" ? styles.on_about_page : styles.about_us
            }
          >
            <Link to="/about-us" className={styles.label}>
              About Us
            </Link>
          </li>
          <li
            className={styles.about_us}
          >
            <Link to="/prohibited-items" className={styles.label}>
             Prohibited Items
            </Link>
          </li>
          {!token && (
            <li className={styles.login}>
              <Link to="/login" className={styles.label}>
                Sign In
              </Link>
            </li>
          )}
          {/* <div className={styles.inputWrapper}>
              <img
                src={SearchSvg}
                alt="search icon"
                className={styles.searchIcon}
              />
              <input
                className={styles.input}
                placeholder="Search for Products"
              />
            </div> */}
          {/* <div className={styles.nav_right}> */}
          {token && (
            <li className={styles.profileWrapper} onClick={toggleDropdown}>
              <img className={styles.profile} src={ProfileImg} alt="" />
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  {/* <button onClick={handLeLogin} className={styles.logoutBtn}>
          Login
        </button> */}
                  <button
                    onClick={() => navigate("/profile")}
                    className={styles.logoutBtn}
                  >
                    Profile
                  </button>
                  <button onClick={handleLogout} className={styles.logoutBtn}>
                    Logout
                  </button>
                </div>
              )}
            </li>
          )}
        </ul>
      </nav>
      <BulkOrder isVisible={isModalOpen} onClose={toggleModal} />
    </>
  );
};

export default Navbar;
